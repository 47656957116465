import { ready } from "@front/utils";
import AlpineInstance, { Alpine, AlpinePlugin, AlpineComponents, AlpineComponent, AlpineDefaults } from "alpinejs";

function createApp (components: AlpineComponents, plugins: AlpinePlugin[] = [], defaults: AlpineDefaults = {}) {
    plugins.forEach(AlpineInstance.plugin);

    Object.assign(AlpineInstance, {
        $defaults: (key: string) => (AlpineInstance.store('defaults') as AlpineDefaults)[key]
    })

    AlpineInstance.store('defaults', defaults);
    AlpineInstance.magic('defaults', (el, { Alpine }) =>
        (key: string) => ({ [key]: Alpine.$data(el)[key] || defaults[key] })
    );

    window.App = {
        vm: AlpineInstance,
        ...components,
    };

    ready(AlpineInstance.start);
}

function createPlugin(cb: (Alpine: Alpine) => void) {
    return cb;
}

function createComponent<T extends AlpineComponent>(cb: (Alpine: Alpine) => T) {
    return () => cb(window.App.vm);
}

function getRef(ctx: Alpine, ref: string): HTMLElement | undefined {
    return ctx.$refs[ref];
}

export {
    getRef,
    createApp,
    createComponent,
    createPlugin,
};

import { sleep } from "@front/utils";
import { CookieStorage } from "@front/services/storage";
import { createComponent } from "@front/utils/alpine";

declare var psemailsubscription_subscription: string;

const POPIN_PAGEVIEWS = 2;

export default createComponent(({ $persist }) => ({
    email: '',
    message: '',
    loading: false,
    submitted: false,
    error: undefined as boolean | undefined,
    popin: $persist({ pageViews: 0, isDismissed: false })
        .as('_x_ps_emailsubscription_popin')
        .using(CookieStorage()),

    isPopinDisplayed () {
        return this.popin.pageViews >= POPIN_PAGEVIEWS && !this.popin.isDismissed;
    },

    async submitData (event: SubmitEvent) {
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);

        this.message = '';
        this.error = undefined;
        this.loading = true;
        // this.popin.isDismissed = true;

        try {
            const response = await fetch(psemailsubscription_subscription, {
                method: 'POST',
                body: new URLSearchParams(<any>formData),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                },
            });

            const data = await response.json();
            await sleep(500);

            this.email = data.value;
            this.message = data.msg;
            this.error = !!data.nw_error;
            this.submitted = true;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    },

    init () {
        this.popin.pageViews = this.popin.pageViews + 1;
    },
}));

import prestashop from "prestashop";
import { createComponent } from "@front/utils/alpine";
import { buildFormData, createNode, unlazyImgNodes } from "@front/utils";

export default createComponent(({ $persist, $defaults }) => ({
    controller: new AbortController(),

    async queryCart (url: string) {
        this.controller ? this.controller.abort() : null;
        this.controller = new AbortController();
        const { signal } = this.controller;

        const opts = {
            signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: buildFormData({
                ajax: '1',
                action: 'update',
            }),
        };

        const reason = Object.fromEntries((new URL(url)).searchParams);
        const response = await fetch(url, opts);
        const resp = await response.json();

        prestashop.emit('updateCart', {
            reason,
            resp,
        });
    },

    async handleUpdateCart (event: any) {
        try {
            const response = await fetch(this.refreshUrl)
            const data = await response.json();

            // Variables disponibles sur data
            // cart_detailed:"<!-- checkout/_partials/cart-detailed.tpl -->..."
            // cart_detailed_actions:"<!-- checkout/_partials/cart-detailed-actions.tpl -->..."
            // cart_detailed_totals:"<!-- checkout/_partials/cart-detailed-totals.tpl -->..."
            // cart_summary_items_subtotal:"<!-- checkout/_partials/cart-summary-items-subtotal.tpl -->..."
            // cart_summary_products:"<!-- checkout/_partials/cart-summary-products.tpl -->..."
            // cart_summary_subtotals_container:"<!-- checkout/_partials/cart-summary-subtotals.tpl -->..."
            // cart_summary_top:"<!-- checkout/_partials/cart-summary-top.tpl -->..."
            // cart_summary_totals:"<!-- checkout/_partials/cart-summary-totals.tpl -->..."
            // cart_voucher:"<!-- checkout/_partials/cart-voucher.tpl -->..."

            if (this.$refs.cart_detailed && data.cart_detailed) {
                const newCartDetailedNode = createNode(data.cart_detailed);
                this.$refs.cart_detailed.replaceWith(
                    unlazyImgNodes(newCartDetailedNode)
                );
            }

            if (this.$refs.cart_detailed_totals && data.cart_detailed_totals) {
                this.$refs.cart_detailed_totals.replaceWith(createNode(data.cart_detailed_totals));
            }

        } catch (resp) {
            console.warn('ERROR', resp)
            prestashop.emit('handleError', { eventType: 'updateCart', resp });
        }
    },

    init () {
        this.refreshUrl = this.$el.dataset.refreshUrl;

        prestashop.on('updateCart', this.handleUpdateCart.bind(this));
    },

}));

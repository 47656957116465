import { Coords } from '@floating-ui/core';
import { computePosition, offset, arrow, Placement } from '@floating-ui/dom';
import { createPlugin } from '@front/utils/alpine';
import { debounce } from 'lodash-es';

async function position (triggerEl: HTMLElement, tooltipEl: HTMLElement, arrowEl: HTMLElement, pos: Placement = 'top') {
    const {
        x,
        y,
        placement,
        middlewareData,
    } = await computePosition(triggerEl, tooltipEl, {
        placement: pos,
        middleware: [
            arrow({ element: arrowEl }),
            offset(10),
        ],
    });

    Object.assign(tooltipEl.style, {
        left: `${x}px`,
        top: `${y}px`,
    });

    const {
        x: arrowX,
        y: arrowY,
    } = middlewareData.arrow as Coords;

    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[placement.split('-')[0]] as keyof CSSStyleDeclaration;

    Object.assign(arrowEl.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-4px',
    });
}

export default createPlugin((vm) => {

    vm.directive('tooltip', (el, { expression, value, modifiers }, { evaluate }) => {
        const trigger = el as HTMLElement;
        const tooltip = evaluate(expression) as HTMLElement;
        const arrow = document.createElement('span');
        const [pos, ..._] = modifiers as Placement[];

        let [event, classes] = ['click', 'peer-focus:block'];

        if (value == 'hover') {
            [event, classes] = ['mouseover', 'peer-hover:block'];
        }

        trigger.addEventListener(event, function init () {
            trigger.classList.add('peer');
            tooltip.classList.add(classes);
            arrow.classList.add('tooltip-arrow');
            tooltip.appendChild(arrow);

            position(trigger, tooltip, arrow, pos);

            trigger.removeEventListener('click', init);
        });

        window.addEventListener('resize', debounce(() => position(trigger, tooltip, arrow, pos), 1000));
    });

});

import { createPlugin } from '@front/utils/alpine';

export default createPlugin((vm) => {

    vm.directive('obfuscated-link', (el, { modifiers, expression }, { evaluate }) => {
        const htmlEl = el as HTMLElement;

        let link = document.createElement('a');
        link.setAttribute('href', modifiers.includes('encrypted') ? atob(expression) : expression);

        let attribute;
        let attributes = Array.from(htmlEl.attributes);

        while (attribute = attributes.pop()) {
            if (attribute.nodeName.startsWith('x-obfuscated-link')) {
                continue;
            }

            link.setAttribute(attribute.nodeName, attribute.nodeValue || '');
        }

        link.innerHTML = htmlEl.innerHTML;

        htmlEl?.parentNode?.replaceChild(link, htmlEl);
    });

});

import prestashop from "prestashop";
import { createComponent } from "@front/utils/alpine";

export default createComponent(() => ({
    qty: 1,
    maxQty: 1,
    price: 0,
    regularPrice: 0,

    plus() {
        this.qty < this.maxQty ? this.qty++ : this.maxQty;
    },

    minus() {
        this.qty <= 1 ? 1 : this.qty--;
    },

    displayPrice(price) {
        return price.toFixed(2).replace('.', ',');
    },

    setup (data) {
        let { qty, maxQty, price, regularPrice } = data;

        this.qty = qty;
        this.maxQty = maxQty;
        this.price = price;
        this.regularPrice = regularPrice;

        prestashop.on('updateCart', (event) => {
            this.qty = 1;
        });
    },
}));

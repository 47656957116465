import { createPlugin } from '@front/utils/alpine';
import { dataLayerPush } from '@front/services/datalayer';

export default createPlugin((vm) => {

    vm.magic('dataLayer', () => {
        return (...data: any) => dataLayerPush(data);
    });

});

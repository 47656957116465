import { getChildIndex } from "@front/utils";
import { createComponent } from "@front/utils/alpine";
import { throttle } from "lodash-es";

const AUTOSCROLL_INTERVAL = 2000;

export default createComponent(({ $persist, $defaults }) => ({
    wrapperEl: null as null | HTMLElement,
    noPrev: true,
    noNext: false,
    scrollIndex: 0,
    autoScroll: false,
    loop: false,

    prev () {
        this.wrapperEl.scrollBy({
            left: -this.wrapperEl.querySelector(':scope > *').offsetWidth,
            behavior: 'smooth',
        });
    },

    next () {
        this.wrapperEl.scrollBy({
            left: this.wrapperEl.querySelector(':scope > *').offsetWidth,
            behavior: 'smooth',
        });
    },

    scrollTo (index: number) {
        const indexEl = this.wrapperEl.querySelector(`:scope > *:nth-child(${index + 1})`);
        this.wrapperEl.scrollTo({
            left: indexEl.offsetLeft,
            behavior: 'smooth',
        });
    },

    setup ({ autoScroll, loop } = {}) {
        this.autoScroll = autoScroll ?? false;
        this.loop = loop ?? false;

        this.wrapperEl = this.$el.querySelector(':scope > *:not(button):not([role="nav"])') as HTMLElement;

        const updateScroll = () => {
            const { scrollLeft, offsetWidth, scrollWidth } = this.wrapperEl;

            this.noPrev = scrollLeft == 0;
            this.noNext = scrollWidth - scrollLeft - offsetWidth <= 0;

            const scrollEl = Array.from<HTMLElement>(this.wrapperEl.querySelectorAll(':scope > *')).find(el =>
                el.offsetLeft >= scrollLeft && scrollLeft < el.offsetLeft + el.offsetWidth
            ) as HTMLElement;

            this.scrollIndex = getChildIndex(this.wrapperEl, scrollEl);
        }

        // @fractodo handle loop

        // @fractodo handle autoScroll
        // const handleAutoScroll = setInterval(() => {
        //     if (!this.autoScroll) {
        //         clearInterval(handleAutoScroll);
        //         return;
        //     }

        //     this.wrapperEl.scrollBy({
        //         left: this.wrapperEl.querySelector(':scope > *').offsetWidth,
        //         behavior: 'smooth',
        //     });
        // }, AUTOSCROLL_INTERVAL);

        this.wrapperEl.addEventListener('scroll', throttle(updateScroll, 150));
        updateScroll();
    },
}));

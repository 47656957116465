import { createComponent } from "@front/utils/alpine";

export default createComponent(() => ({
    step: null,
    substep: '',
    stepWithError: null,
    steps: {
        1: {
            identifier: 'checkout-personal-information-step',
            substep: ''
        },
        2: {
            identifier: 'checkout-addresses-step',
            substep: ''
        },
        3: {
            identifier: 'checkout-delivery-step',
            substep: ''
        },
        4: {
            identifier: 'checkout-payment-step',
            substep: ''
        }
    },

    stepError (step: number, subStep: string = '') {
        this.stepWithError = step;

        if (typeof subStep !== 'undefined') {
            this.steps[this.stepWithError].substep = subStep;
        }

        const stepIdentifier = this.steps[this.stepWithError].identifier;
        const stepId = document.getElementById(stepIdentifier);
        
        if (typeof stepIdentifier !== 'undefined' && stepId != null) {
            stepId.scrollIntoView();
        }
    },

}));

function getCssVar(varName: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

function getBreakpoint(name: string) {
    const val = getCssVar(`--breakpoint-${name}`).replace('px', '');
    return parseInt(val, 10);
}

function nextTick(func: (...args: any[]) => void) {
    setTimeout(func, 0);
}

function ready(func: EventListener): void {
    if (document.readyState !== 'loading') {
        return func(new Event('ready'));
    }

    document.addEventListener('DOMContentLoaded', func);
}

function getChildIndex(parent: HTMLElement, child: HTMLElement) {
    return Array.from(parent.children).indexOf(child);
}

type TWithElRoot = string | HTMLElement | null;

function withEl<T extends HTMLElement = HTMLElement>(selector: string | T, func: (value: T, key: number) => void, options: { ready?: boolean, root?: TWithElRoot } = {}): void {
    let roots = [document.body];

    if (options.root) {
        roots = (typeof options.root == 'string' ? Array.from(document.querySelectorAll(options.root)) : [options.root]);
    }

    const els = typeof selector == 'object' ? [selector] : roots.flatMap(root => Array.from<T>(root.querySelectorAll(selector)));

    if (options.ready !== true) {
        els.forEach(func);
        return;
    }

    return ready(() => {
        els.forEach(func);
    });
}

function setListener<T extends HTMLElement = HTMLElement>(selector: string | T, event: string, func: (evt: Event, el: HTMLElement) =>void, options: { prevent?: boolean, ready?: boolean } & AddEventListenerOptions = {}) {
    withEl(selector, el => {
        el.addEventListener(event, (e) => {
            if (options.prevent) {
                e.preventDefault();
            }

            func(e, el);
        }, options)
    }, options);
}

function whenIntersecting(selector: string, func: (entry: IntersectionObserverEntry, observer: IntersectionObserver) => void, options = {}) {
    const observer = new IntersectionObserver(entries => {
        entries.forEach((entry) => {
            if (! entry.isIntersecting) {
                return;
            }

            func(entry, observer);
        }, options);
    });

    withEl(selector, el => {
        observer.observe(el);
    }, options);

    return observer;
}

function setPrevent(selector: string, event: string) {
    withEl(selector, el => {
        el.addEventListener(event, (e) => e.preventDefault());
    });
}

function getBoundingClientOffset(el: HTMLElement) {
    if (el === document.body) {
        return { left: 0, top: 0 };
    }

    return el.getBoundingClientRect();
}

function mouseEventOffset(event: MouseEvent, target: HTMLElement) {
    target = target || event.currentTarget || event.srcElement;

    const cx = event.clientX || 0;
    const cy = event.clientY || 0;
    const rect = getBoundingClientOffset(target);

    return {
        offsetX: cx - rect.left,
        offsetY: cy - rect.top
    };
}

function getCsrfToken(metaname: string = 'csrf-token') {
    return document.querySelector<HTMLMetaElement>(`meta[name="${metaname}"]`)?.content || '';
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function omitEnumKeys<T, K extends keyof T, R extends Omit<T, K>>(list: T, ...keys: K[]): R {
    const entries = Object.entries(list) as [K, any][];

    return Object.fromEntries(
        entries.filter(([k, _]) => !keys.includes(k))
    ) as R;
}

function px(val: number): string {
    return `${val}px`;
}

function buildFormData(obj: {[key: string]: any}) {
    const formData = new FormData();
    Object.entries(obj).forEach(entry => formData.append(...entry));

    return formData;
}

function createNode(htmlString: string) {
    const el = document.createElement('div');
    el.innerHTML = htmlString;

    return el.firstElementChild as HTMLElement;
}

function unlazyImgNodes(node: HTMLElement): HTMLElement {
    node.querySelectorAll('img.lazy').forEach(img => {
        img.setAttribute('src', img.getAttribute('data-src') || '');
        img.removeAttribute('data-src');
        img.classList.remove('lazy');
    });

    return node;
}

export {
    getCsrfToken,
    getCssVar,
    getBreakpoint,
    getChildIndex,
    omitEnumKeys,
    createNode,
    ready,
    withEl,
    setPrevent,
    setListener,
    sleep,
    whenIntersecting,
    nextTick,
    TWithElRoot,
    px,
    buildFormData,
    mouseEventOffset,
    unlazyImgNodes,
};

import { CookieStorage } from "@front/services/storage";
import { createComponent } from "@front/utils/alpine";

export default createComponent(({ $persist }) => ({
    display: $persist(true)
        .as('_x_fractotopbanner_display')
        .using(CookieStorage()),

    hide () {
        this.display = false;
    },
}));

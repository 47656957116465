import { createComponent } from "@front/utils/alpine";

class Marker {
    lat?: number;
    lng?: number;
    name?: string;
    link?: string;
    productCount = 0;
    artisanCount = 0;
    disabled = false;
    isOpen = false;

    constructor (args: keyof Marker) {
        Object.assign(this, args);
    }

    get position () {
        return {
            left: `${this.lat}%`,
            top: `${this.lng}%`,
        };
    }
}

export default createComponent(() => ({
    markers: [] as Marker[],

    setup () {
        const content = this.$el.querySelector<HTMLMetaElement>('meta[name="markers"]')?.content ?? '[]';

        this.markers = JSON.parse(content).map((marker: Partial<keyof Marker>) =>
            new Marker(marker)
        );
    },
}));

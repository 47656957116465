import { Logger } from '@front/Logger';
import { createComponent } from "@front/utils/alpine";
import { dataLayerPush } from '@front/services/datalayer';
import { CookieStorage } from "@front/services/storage";

type TConsentType = 'ad_storage' | 'analytics_storage' | 'functionality_storage' | 'personalization_storage' | 'security_storage';

const logger = new Logger('GTM_CONSENT', 'info');

/**
 * @see https://developers.google.com/tag-platform/devguides/consent?tab=tag-manager#implementation_example
 */
export default createComponent(({ $persist, $defaults }) => ({
    isPopinActive: false,
    defaultConsent: {},
    currentConsents: {},
    persistedConsent: $persist({})
        .as('_x_gtm_consent')
        .using(CookieStorage(395)), // 395 jours = 13 mois


    setup (defaultConsent): void {
        this.defaultConsent = defaultConsent;
        this.currentConsents = defaultConsent;

        logger.log('init with default consent', this.defaultConsent);

        dataLayerPush('consent', 'default', this.defaultConsent);

        if (! this.hasPersistedConsent()) {
            this.isPopinActive = true;
        } else {
            this.currentConsents = this.persistedConsent;
            this.updateGtmConsent();
        }
    },

    hasPersistedConsent(): boolean {
        return Object.keys(this.persistedConsent).length > 0;
    },

    hasConsented (consent: TConsentType): boolean {
        return this.currentConsents[consent] == 'granted';
    },

    grantConsent (consent: TConsentType): void {
        logger.log(`grant consent ${consent}`);

        this.currentConsents[consent] = 'granted';
    },

    denyConsent (consent: TConsentType): void {
        logger.log(`deny consent ${consent}`);

        this.currentConsents[consent] = 'denied';
    },

    updateGtmConsent (): void {
        this.isPopinActive = false;

        logger.log('update consent', this.currentConsents);

        this.persistedConsent = this.currentConsents;

        dataLayerPush('consent', 'update', { ...this.persistedConsent });
    },

    showPopin (): void {
        this.isPopinActive = true;
    }
}));

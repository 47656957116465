import { mouseEventOffset } from "@front/utils";
import { createComponent } from "@front/utils/alpine";

export default createComponent(() => ({
    cursorInside: false,

    setup () {
        const computePos = (event: MouseEvent) => {
            this.cursorInside = true;

            const {
                offsetX,
                offsetY
            } = mouseEventOffset(event, this.$el);

            const outerOffsetX = this.$refs.cursor.offsetWidth * 0.75;
            const outerOffsetY = this.$refs.cursor.offsetHeight * 0.75;

            this.$refs.cursor.style.setProperty('--pos-x', `${offsetX - outerOffsetX}px`);
            this.$refs.cursor.style.setProperty('--pos-y', `${offsetY - outerOffsetY}px`);
        }

        this.$el.addEventListener('mouseenter', computePos);
        this.$el.addEventListener('mousemove', computePos);
        this.$el.addEventListener('mouseleave', () => {
            this.cursorInside = false;

            this.$refs.cursor.style.removeProperty('--pos-x');
            this.$refs.cursor.style.removeProperty('--pos-y');
        });
    },
}));

import { Logger } from '@front/Logger';

declare global {
    interface Window {
        dataLayer: any[],
        gtag: Function,
    }
}

const logger = new Logger('DATALAYER', 'info');

function dataLayerPush(...data: any): void {
    window.dataLayer.push(arguments);
    logger.log('push', data);
}

window.dataLayer = window.dataLayer || [];
window.gtag = dataLayerPush;

export {
    dataLayerPush,
}

import prestashop from 'prestashop';
import { omitEnumKeys, sleep } from '@front/utils';
import { createComponent, getRef } from '@front/utils/alpine';
import { Alpine } from 'alpinejs';

enum EStaticDOM {
    FACETS_WRAPPER      = 'static_facets_wrapper',
    PRODUCTS_CONTAINER  = 'static_productlist',
}

enum ERenderedDOM {
    FACETS          = 'rendered_facets',
    ACTIVE_FILTERS  = 'rendered_active_filters',
    PRODUCTS_TOP    = 'rendered_products_top',
    PRODUCTS        = 'rendered_products',
    PRODUCTS_BOTTOM = 'rendered_products_bottom',
};

enum EQUERY_TYPES {
    FACETS,
    NEXT_PAGE,
}

function updateProductListDOM (ctx: Alpine, data: any, views: Partial<typeof ERenderedDOM> = ERenderedDOM) {
    const getNodeFromData = (key: string) => {
        const el = document.createElement('div');
        el.innerHTML = data[key];

        return el.firstElementChild;
    }

    for (const [_, key] of Object.entries(views)) {
        const oldNode = getRef(ctx, key) as HTMLElement | undefined;
        const newNode = getNodeFromData(key) as HTMLElement | null;

        if (!oldNode) {
            console.warn(`template ref "${key}" not found.`);
            continue;
        }

        if (!newNode) {
            console.warn(`no fresh data for node "${key}".`);
            continue;
        }

        oldNode.parentNode?.replaceChild(newNode, oldNode);
    }
}

function appendProductListDom (ctx: Alpine, data: any) {
    const el = document.createElement('div');
    el.innerHTML = data[ERenderedDOM.PRODUCTS];

    const containerEl = getRef(ctx, EStaticDOM.PRODUCTS_CONTAINER);
    const productsEl = el.firstElementChild?.querySelectorAll(':scope > [x-ref] > *') || [];

    containerEl?.insertAdjacentHTML('beforeend', Array.from(productsEl).map(e => e.outerHTML).join(''));
}

export default createComponent(() => ({
    currentQuery: null as EQUERY_TYPES | null,
    history: window.location.href,

    isReady: false,
    isLoading: false,
    isOpened: false,

    updateFacets (url: string) {
        this.isLoading = true;
        this.currentQuery = EQUERY_TYPES.FACETS;
        prestashop.emit('updateFacets', url);
    },

    nextPage (url: string) {
        this.isLoading = true;
        this.currentQuery = EQUERY_TYPES.NEXT_PAGE;
        prestashop.emit('updateFacets', url);
    },

    firstPage (url: string) {
        this.isLoading = true;
        window.location.href = url;
    },

    toggle (force: boolean) {
        this.isOpened = force == undefined ? !this.isOpened : force;
    },

    init () {
        let ctx = this;
        this.isReady = true;

        window.addEventListener('popstate', ({ state }) =>
            window.location.href = state && state.current_url ? state.current_url : history
        );

        prestashop.on('updateProductList', (data) => {
            if (this.currentQuery == EQUERY_TYPES.NEXT_PAGE) {
                updateProductListDOM(ctx, data, omitEnumKeys(ERenderedDOM, 'PRODUCTS'));
                appendProductListDom(ctx, data);
            } else {
                updateProductListDOM(ctx, data);
            }

            this.currentQuery = null;
            this.isLoading = false;
        });
    },
}));

import { buildFormData, createNode, nextTick, sleep, unlazyImgNodes } from "@front/utils";
import { createComponent } from "@front/utils/alpine";
import prestashop from "prestashop";

export default createComponent(() => ({
    isOpened: false,
    isModalOpen: false,
    refreshUrl: null as null | string,

    openModalWithContent (html: string) {
        const node = createNode(html);
        this.$el.appendChild(node);

        nextTick(() => this.isModalOpen = true);
    },

    closeModal (modal: any) {
        this.isModalOpen = false;
        sleep(300).then(() => modal.remove());
    },

    controller: new AbortController(),

    checkQuantity () {
        if (this.qty >= this.max_qty) {
            // console.warn(this.$refs.addproduct);
            this.$refs.addproduct.classList.add('opacity-0');
            this.$refs.addproduct.classList.add('cursor-default');

            return false;
        }

        this.$refs.addproduct.classList.remove('opacity-0');
        this.$refs.addproduct.classList.remove('cursor-default');

        return true;
    },

    async queryCart (url: string) {
        this.controller ? this.controller.abort() : null;
        this.controller = new AbortController();
        const { signal } = this.controller;

        const opts = {
            signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: buildFormData({
                ajax: '1',
                action: 'update',
            }),
        };

        const reason = Object.fromEntries((new URL(url)).searchParams);
        const response = await fetch(url, opts);
        const resp = await response.json();

        prestashop.emit('updateCart', {
            reason,
            resp,
        });
    },

    async handleUpdateCart (event: any) {
        const requestData = {};
        const addToCartForm = document.querySelector<HTMLFormElement>('.js-add-to-cart-or-refresh');

        if (event && event.reason && typeof event.resp !== 'undefined' && !event.resp.hasError) {
            Object.assign(requestData, {
                id_customization: event.reason.idCustomization,
                id_product_attribute: event.reason.idProductAttribute,
                id_product: event.reason.idProduct,
                action: event.reason.linkAction
            }, addToCartForm ? Object.fromEntries(new FormData(addToCartForm)) : {});
        }

        if (event && event.resp && event.resp.hasError) {
            prestashop.emit('showErrorNextToAddtoCartButton', { errorMessage: event.resp.errors.join('<br/>')});
        }

        const opts = {
            method: 'POST',
            body: buildFormData(requestData),
        };

        try {
            const response = await fetch(this.refreshUrl, opts)
            const data = await response.json();

            const newNode = createNode(data.preview);
            const oldNode = this.$el;

            const newButtonNode = newNode.firstElementChild as Element;
            const oldButtonNode = oldNode.firstElementChild as Element;
            this.$el.replaceChild(newButtonNode, oldButtonNode);

            const newContentNode = newNode?.querySelector('aside');
            const oldContentNode = oldNode?.querySelector('aside');
            if (newContentNode && oldContentNode) {
                this.$el.replaceChild(unlazyImgNodes(newContentNode), oldContentNode);
            }

            data.modal ? this.openModalWithContent(data.modal) : null;
        } catch (resp) {
            prestashop.emit('handleError', { eventType: 'updateShoppingCart', resp });
        }
    },

    init () {
        this.refreshUrl = this.$el.dataset.refreshUrl;

        prestashop.on('updateCart', this.handleUpdateCart.bind(this));
    },

}));

import 'webp-in-css/polyfill';

import './lib/lazyload';

import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import collapse from '@alpinejs/collapse';

import fractotopbanner from './modules/fractotopbanner';
import ps_emailsubscription from './modules/ps_emailsubscription';
import ps_shoppingcart from './modules/ps_shoppingcart';

import datalayer from './plugins/datalayer';
import tooltip from './plugins/tooltip';
import obfuscatedLink from './plugins/obfuscated-link';

import cart from './components/cart';
import destinationMap from './components/destination-map';
import facets from './components/facets';
import gtmConsent from './components/gtm-consent';
import product from './components/product';
import productActions from './components/product-actions';
import slider from './components/slider';
import checkout from './components/checkout';

import { createApp } from './utils/alpine';

const modules = {
    fractotopbanner,
    ps_emailsubscription,
    ps_shoppingcart,
};

const components = {
    cart,
    destinationMap,
    facets,
    gtmConsent,
    product,
    productActions,
    slider,
    checkout
};

createApp({
    modules,
    components,
}, [
    intersect,
    persist,
    collapse,
    tooltip,
    datalayer,
    obfuscatedLink,
], {

});
